
import { defineComponent, ref, PropType, computed, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ServiceError } from '@/services/util/ServiceError';
import User, { UserFormDto } from '@/models/User';
import { useToast } from 'primevue/usetoast';
import i18n from '@/i18n';
import { useProfileStore } from '@/store/profile.module';
import { isProfileConfigFormValid, profileConfigurationForm } from '@/validation/profileForm';
import inputHelper from '@/helpers/Input.helper';
import CardComponent from '@/components/CardComponent.vue';

export default defineComponent({
  components: { CardComponent },
  props: {
    profile: {
      type: Object as PropType<User>,
      required: true,
    },
    profilePic: {
      type: String,
    },
  },
  setup(props) {
    const { t, availableLocales } = useI18n();
    const toast = useToast();
    const storeProfile = useProfileStore();
    const mutableProfile: Ref<UserFormDto> = ref(new UserFormDto(props.profile));
    const validatedForm = computed(() => profileConfigurationForm(mutableProfile.value));
    const valid = computed(() => isProfileConfigFormValid(validatedForm.value));
    const languageOptions = ref(inputHelper.getLangOptions(availableLocales));
    const editMode = ref(false);
    const submiting = ref(false);
    const profileBackup = ref(mutableProfile.value);

    const onSubmit = async () => {
      if (!valid.value) {
        return;
      }
      await modifyProfile(mutableProfile.value);
    };

    const onEditForm = () => {
      mutableProfile.value = new UserFormDto(props.profile);
      profileBackup.value = inputHelper.createCopy<UserFormDto>(mutableProfile.value);
      editMode.value = true;
    };

    const onCancelEdition = async () => {
      editMode.value = false;
      mutableProfile.value = inputHelper.createCopy<UserFormDto>(profileBackup.value);
    };

    async function modifyProfile(profile: UserFormDto) {
      submiting.value = true;
      const result = await storeProfile.modifyProfile(profile);
      i18n.global.locale.value = profile.language;
      submiting.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditProfile')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditProfile')}`, life: 3000 });
        editMode.value = false;
      }
    }

    return {
      mutableProfile,
      validatedForm,
      languageOptions,
      editMode,
      submiting,
      onSubmit,
      onEditForm,
      onCancelEdition,
    };
  },
});
