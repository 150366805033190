
import { defineComponent, ref, PropType, computed, inject, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ServiceError } from '@/services/util/ServiceError';
import User, { UserFormDto } from '@/models/User';
import { useToast } from 'primevue/usetoast';
import { useProfileStore } from '@/store/profile.module';
import { isProfileBasicInformationFormValid, profileBasicInformationForm } from '@/validation/profileForm';
import HealzInput from '@/components/healz/ui/HealzInput.vue';
import inputHelper from '@/helpers/Input.helper';
import CardComponent from '@/components/CardComponent.vue';

export default defineComponent({
  components: { HealzInput, CardComponent },
  props: {
    profile: {
      type: Object as PropType<User>,
      required: true,
    },
    profilePic: {
      type: String,
    },
  },
  setup(props) {
    const auth: any = inject('Auth');
    const { t } = useI18n();
    const toast = useToast();
    const storeProfile = useProfileStore();
    const mutableProfile: Ref<UserFormDto> = ref(new UserFormDto(props.profile));
    const validatedForm = computed(() => profileBasicInformationForm(mutableProfile.value));
    const valid = computed(() => isProfileBasicInformationFormValid(validatedForm.value));
    const editMode = ref(false);
    const submiting = ref(false);
    const profileBackup = ref(mutableProfile.value);
    const userTypeOptions = ref(inputHelper.getUserTypeOptions(t));
    const message = ref();
    const isAdminUser = computed(() => storeProfile.isAdmin);

    const rolesWithDisableOptions = computed(() => {
      return userTypeOptions.value.map((role) => {
        return {
          value: role.value,
          label: role.label,
          disable: props.profile.roles && props.profile.roles.includes(role.value),
        };
      });
    });

    const onSubmit = async () => {
      if (!valid.value) {
        return;
      }
      await modifyProfile(mutableProfile.value);
    };

    const onEditForm = () => {
      mutableProfile.value = new UserFormDto(props.profile);
      profileBackup.value = inputHelper.createCopy<UserFormDto>(mutableProfile.value);
      editMode.value = true;
    };

    const onCancelEdition = async () => {
      editMode.value = false;
      mutableProfile.value = inputHelper.createCopy<UserFormDto>(profileBackup.value);
    };

    const onChangePassword = async () => {
      auth
        .resetPassword(props.profile.email)
        .then(function () {
          message.value = {
            severity: 'success',
            content: t('login.passwordResetSuccess'),
          };
        })
        .catch(function () {
          message.value = {
            severity: 'warn',
            content: t('common.error'),
          };
        });
    };

    async function modifyProfile(profile: UserFormDto) {
      submiting.value = true;
      const result = await storeProfile.modifyProfile(profile);
      submiting.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditProfile')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditProfile')}`, life: 3000 });
        editMode.value = false;
      }
    }

    return {
      mutableProfile,
      userTypeOptions,
      validatedForm,
      editMode,
      submiting,
      message,
      onSubmit,
      onEditForm,
      onCancelEdition,
      onChangePassword,
      isAdminUser,
      rolesWithDisableOptions,
    };
  },
});
