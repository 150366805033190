
import { defineComponent, onMounted, computed, Ref } from 'vue';
import { useProfileStore } from '@/store/profile.module';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ProfileBasicInformationCard from '@/components/profile/ProfileBasicInformationCard.vue';
import ProfileConfigurationCard from '@/components/profile/ProfileConfigurationCard.vue';
import { useRoute } from 'vue-router';
import User from '@/models/User';
import HeadingComponent from '@/components/HeadingComponent.vue';

export default defineComponent({
  components: { LoadingComponent, ProfileBasicInformationCard, ProfileConfigurationCard, HeadingComponent },
  setup() {
    const storeProfile = useProfileStore();
    const route = useRoute();
    const profile: Ref<User | undefined> = computed(() => storeProfile.getProfile);
    const profilePic = computed(() => profile.value?.picture);

    onMounted(async () => {
      await storeProfile.fetchProfile();
    });
    return { route, profile, profilePic };
  },
});
