import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = {
  key: 1,
  class: "p-grid"
}
const _hoisted_3 = { class: "p-col-5" }
const _hoisted_4 = { class: "p-col-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_ProfileBasicInformationCard = _resolveComponent("ProfileBasicInformationCard")!
  const _component_ProfileConfigurationCard = _resolveComponent("ProfileConfigurationCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast),
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : '')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.profile)
        ? (_openBlock(), _createBlock(_component_LoadingComponent, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ProfileBasicInformationCard, {
                profile: _ctx.profile,
                profilePic: _ctx.profilePic
              }, null, 8, ["profile", "profilePic"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ProfileConfigurationCard, { profile: _ctx.profile }, null, 8, ["profile"])
            ])
          ]))
    ])
  ], 64))
}