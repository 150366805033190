import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-col p-fluid" }
const _hoisted_4 = { class: "p-field" }
const _hoisted_5 = { for: "name" }
const _hoisted_6 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_7 = { class: "p-field" }
const _hoisted_8 = { for: "surname" }
const _hoisted_9 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_10 = { class: "p-field" }
const _hoisted_11 = { for: "email" }
const _hoisted_12 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_13 = { class: "p-field" }
const _hoisted_14 = { for: "roles" }
const _hoisted_15 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_16 = { class: "p-field" }
const _hoisted_17 = { for: "password" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_HealzInput = _resolveComponent("HealzInput")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Message = _resolveComponent("Message")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_CardComponent, {
    cardTitle: _ctx.$t('common.basic-info'),
    "edit-mode": _ctx.editMode
  }, {
    headingActions: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_Button, {
        class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
        onClick: _ctx.onEditForm
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: "icon-edit",
            "aria-label": _ctx.$t('common.edit'),
            "aria-hidden": "true"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["class", "onClick"])), [
        [
          _directive_tooltip,
          _ctx.$t('common.edit'),
          void 0,
          { bottom: true }
        ]
      ])
    ]),
    cardContent: _withCtx(() => [
      _createElementVNode("form", _hoisted_2, [
        _createVNode(_component_Avatar, {
          image: _ctx.profilePic,
          class: "p-m-2",
          size: "xlarge",
          shape: "circle"
        }, null, 8, ["image"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('person.name')) + " " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
            _createVNode(_component_HealzInput, {
              id: "name",
              modelValue: _ctx.mutableProfile.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mutableProfile.name) = $event)),
              modelModifiers: { trim: true },
              editable: _ctx.editMode,
              class: _normalizeClass({ 'p-invalid': _ctx.editMode && !_ctx.validatedForm.name.valid, 'no-editable': !_ctx.editMode })
            }, null, 8, ["modelValue", "editable", "class"]),
            (_ctx.editMode && !_ctx.validatedForm.name.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.$t(`${_ctx.validatedForm.name.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('person.surname')) + " " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
            _createVNode(_component_HealzInput, {
              id: "surname",
              modelValue: _ctx.mutableProfile.surname,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mutableProfile.surname) = $event)),
              modelModifiers: { trim: true },
              editable: _ctx.editMode,
              class: _normalizeClass({ 'p-invalid': _ctx.editMode && !_ctx.validatedForm.surname.valid, 'no-editable': !_ctx.editMode })
            }, null, 8, ["modelValue", "editable", "class"]),
            (_ctx.editMode && !_ctx.validatedForm.surname.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.$t(`${_ctx.validatedForm.surname.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('person.email')) + " " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
            _createVNode(_component_HealzInput, {
              id: "email",
              modelValue: _ctx.mutableProfile.email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mutableProfile.email) = $event)),
              modelModifiers: { trim: true },
              editable: _ctx.editMode,
              class: _normalizeClass(["p-disabled", { 'p-invalid': _ctx.editMode && !_ctx.validatedForm.email.valid, 'no-editable': !_ctx.editMode }])
            }, null, 8, ["modelValue", "editable", "class"]),
            (_ctx.editMode && !_ctx.validatedForm.email.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.$t(`${_ctx.validatedForm.email.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('user.user-type')) + " " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
            _createVNode(_component_MultiSelect, {
              id: "roles",
              modelValue: _ctx.mutableProfile.roles,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.mutableProfile.roles) = $event)),
              options: _ctx.rolesWithDisableOptions,
              "option-label": "label",
              "option-value": "value",
              class: _normalizeClass([
                'multiselect-custom',
                {
                  'p-invalid': !_ctx.validatedForm.roles.valid,
                  'no-editable': !_ctx.editMode,
                  'p-disabled': !_ctx.editMode || (_ctx.editMode && !_ctx.isAdminUser),
                },
              ]),
              optionDisabled: "disable"
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.editMode && !_ctx.validatedForm.roles.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_15, _toDisplayString(_ctx.$t(`${_ctx.validatedForm.roles.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('login.password')), 1),
            _createVNode(_component_Button, {
              class: "p-button-link",
              label: _ctx.$t('login.pasword-reset'),
              onClick: _ctx.onChangePassword
            }, null, 8, ["label", "onClick"]),
            (_ctx.message)
              ? (_openBlock(), _createBlock(_component_Message, {
                  key: 0,
                  severity: _ctx.message.severity
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.message.content), 1)
                  ]),
                  _: 1
                }, 8, ["severity"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    footerActions: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-secondary p-button-medium",
        label: _ctx.$t('common.cancel'),
        onClick: _ctx.onCancelEdition
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        class: "p-button p-button-info p-button-medium",
        loading: _ctx.submiting,
        label: _ctx.$t('common.save'),
        onClick: _ctx.onSubmit
      }, null, 8, ["loading", "label", "onClick"])
    ]),
    _: 1
  }, 8, ["cardTitle", "edit-mode"]))
}