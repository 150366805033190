import { UserFormDto } from '@/models/User';
import { isObjectValid, required, validateEmail, ValidationResult } from './form';

export interface ProfileBasicInfomationFormValidity {
  name: ValidationResult;
  surname: ValidationResult;
  email: ValidationResult;
  roles: ValidationResult;
}

export interface ProfileConfigurationFormValidity {
  language: ValidationResult;
}

export function isProfileBasicInformationFormValid(form: ProfileBasicInfomationFormValidity) {
  return isObjectValid<ProfileBasicInfomationFormValidity>(form);
}

export function isProfileConfigFormValid(form: ProfileConfigurationFormValidity) {
  return isObjectValid<ProfileConfigurationFormValidity>(form);
}

export function profileBasicInformationForm(user: UserFormDto): ProfileBasicInfomationFormValidity {
  const name = required(user.name);
  const surname = required(user.surname);
  const email = validateEmail(user.email);
  const roles = required(user.roles);

  return {
    name,
    surname,
    email,
    roles,
  };
}

export function profileConfigurationForm(user: UserFormDto): ProfileConfigurationFormValidity {
  const language = required(user.language);

  return {
    language,
  };
}
